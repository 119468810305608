<template>
  <component :is="commonComponent" id="disclamimer" title="Disclamimer">
    <section id="disclamimer-content" class="pc_padding">
      <div class="common-content-row">
        <ol>
          <li>
            <strong>Content Accuracy</strong>: While we strive for accuracy,
            {{ domainConfig['IAmURL'] }} makes no warranties or representations about the
            completeness, reliability, or suitability of the content for any purpose.
          </li>
          <li>
            <strong>Public Domain Resources</strong>: All resources are believed to be in the public
            domain. If you believe there is a copyright issue, please contact us immediately, and we
            will address your concerns promptly.
          </li>
          <li>
            <strong>Liability</strong>: {{ domainConfig['IAmURL'] }} is not liable for any direct,
            indirect, incidental, or consequential damages arising from the use of this site or the
            content therein.
          </li>
          <li>
            <strong>External Links</strong>: Our site may contain links to third-party websites.
            These links are provided for convenience and informational purposes only. We do not
            endorse or assume responsibility for the content or practices of these sites.
          </li>
          <li>
            <strong>No Professional Advice</strong>: The content on this site is for informational
            purposes only and should not be considered professional advice. Always seek the advice
            of a qualified professional with any questions you may have.
          </li>
        </ol>
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
const PcCommon = () => import('@/pages/components/page_common.vue');
const MobileCommon = () => import('@/pages/components/m_back_common');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: `Disclaimer | ${this.domainConfig?.['meta']?.title || 'Default Title'}`,
      meta: [
        {
          name: 'description',
          content: `${this.domainConfig?.['meta']?.des || 'Default des'}`
        },
      ],
    };
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>